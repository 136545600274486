import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import JotformEmbed from 'react-jotform-embed';

import { Layout, Block, Header, MetaTags } from '../components'

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown, darkBrown } from '../styles/colors';
import { heading, paragraph, blockHeading } from '../styles/type';
import themes, { getThemeData } from '../styles/themes';
import { media } from '../styles/utils';


class Terms extends React.Component {

	state = {

	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressTerms.acf_json),
		})
    }

    renderInfoBlocks = () => {
        const { data } = this.state;

		return data.info && data.info.map((item, i) => {            
            if(item.display){
                return (
                    <InfoItem
                        key={i}
                    >
                        <Heading>{item.heading}</Heading>
                        <Text
                            dangerouslySetInnerHTML={{__html: item.text}}  
                        />
                    </InfoItem>
                )
            }
		})
	}


	render() {       
        const { showForm, activeForm, data } = this.state;

		return (
			<Layout
				// Replace with term slug 
				theme={'residential-architecture'} 
				footerImage={data.footer_bg_image && data.footer_bg_image.sizes.xlarge}
			>	
                <MetaTags
                    title={'Apply'}
                    description={''}
                />
				<Wrapper>
					<Header
						showNav={true}
						showSubmit={true}
						sticky={true}
						theme={{
							colour: brown,
							background: grey
						}}
					/>

                    <Title>Terms and Conditions</Title>

                    <Container>

                        <InfoBlocks>
                            {this.renderInfoBlocks()}  
                        </InfoBlocks>

                    </Container>
                    
				</Wrapper>
			</Layout>	
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`



const Container = styled.div`
	${container};
	${padding};
	flex-direction: column;
	width: 100%;
    background: ${grey};
	padding-top: 100px;
`



const Title = styled.h1`
    ${heading};
    text-transform: uppercase;
    max-width: 520px;
    text-align: center;
    margin-top: 145px;
    margin-bottom: 110px;

	${media.phone`
		margin-top: 96px;
		margin-bottom: 64px;
	`}
`
const Heading = styled.div``
const Text = styled.div``

const InfoBlocks = styled.div` 
    display: flex;
    flex-direction:column;
    align-items: center;
`

const InfoItem = styled.div`
    display: flex; 
    border-top: 1px solid ${darkBrown};
    padding-top: 40px;
    margin-bottom: 80px;

    ${Heading} {
        flex: 0 1 50%;

        ${blockHeading}
        font-weight: bold;
        margin-bottom: 0;
        border: 0;
    }

    ${Text} {
        flex: 0 1 50%;
        p {
            color: ${darkBrown};
            ${paragraph};
        }
    }

    ${media.phone`
        flex-direction: column;
        margin-bottom: 48px;
    `}
`

export default Terms

export const query = graphql`
	query {
		wordpressTerms {
			acf_json	
		}
	}
`